export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [];

export const dictionary = {
		"/(dashboard)": [18,[2]],
		"/(public)/404": [80],
		"/(dashboard)/activity": [19,[2,3]],
		"/(dashboard)/activity/quickbooks": [20,[2,3]],
		"/(dashboard)/activity/scheduled": [21,[2,3]],
		"/(dashboard)/addressbook": [22,[2,4]],
		"/(dashboard)/addressbook/customers": [23,[2,4]],
		"/(dashboard)/announcements": [24,[2]],
		"/(dashboard)/approvals": [25,[2,5]],
		"/(dashboard)/approvals/others": [26,[2,5]],
		"/(dashboard)/backoffice": [27,[2]],
		"/(dashboard)/backoffice/mining": [28,[2]],
		"/(dashboard)/backoffice/mining/assets": [29,[2]],
		"/(dashboard)/backoffice/mining/credits": [30,[2]],
		"/(dashboard)/backoffice/mining/workers2": [32,[2]],
		"/(dashboard)/backoffice/mining/workers": [31,[2]],
		"/(dashboard)/backoffice/newcustomer": [33,[2]],
		"/(dashboard)/backoffice/playground": [34,[2,6]],
		"/(dashboard)/backoffice/playground/mpctest": [35,[2,6]],
		"/(dashboard)/backoffice/reports": [36,[2]],
		"/(dashboard)/backoffice/rsa": [37,[2]],
		"/(dashboard)/backoffice/transactions": [38,[2]],
		"/(dashboard)/backoffice/users": [39,[2]],
		"/(public)/clear": [81,[16]],
		"/(dashboard)/deposit": [40,[2,7]],
		"/(dashboard)/deposit/coinbase": [41,[2,7]],
		"/(public)/farewell": [82],
		"/(dashboard)/helpdesk": [42,[2]],
		"/(dashboard)/invoices": [43,[2]],
		"/(public)/invoice": [83],
		"/(login)/login": [78,[14]],
		"/(public)/mpctest": [84],
		"/(dashboard)/noaccess": [44,[2]],
		"/(dashboard)/paymentssinglepage": [51,[2,9]],
		"/(dashboard)/paymentssinglepage/amount": [52,[2,9]],
		"/(dashboard)/paymentssinglepage/confirm": [53,[2,9]],
		"/(dashboard)/paymentssinglepage/destination": [54,[2,9]],
		"/(dashboard)/paymentssinglepage/success": [55,[2,9]],
		"/(dashboard)/payments": [46,[2,8]],
		"/(dashboard)/payments/amount": [47,[2,8]],
		"/(dashboard)/payments/confirm": [48,[2,8]],
		"/(dashboard)/payments/destination": [49,[2,8]],
		"/(dashboard)/payments/success": [50,[2,8]],
		"/(dashboard)/pay": [45,[2]],
		"/(login)/register": [79,[15]],
		"/(dashboard)/settings": [56,[2]],
		"/(dashboard)/settings/accounts": [57,[2]],
		"/(dashboard)/settings/billing": [58,[2]],
		"/(dashboard)/settings/gas": [59,[2,10]],
		"/(dashboard)/settings/gas/details": [60,[2,10]],
		"/(dashboard)/settings/gateway": [61,[2,11]],
		"/(dashboard)/settings/gateway/fees": [62,[2,11]],
		"/(dashboard)/settings/gateway/gatewaysettings": [63,[2,11]],
		"/(dashboard)/settings/notifications": [64,[2]],
		"/(dashboard)/settings/personal/appearance": [65,[2,12]],
		"/(dashboard)/settings/personal/user": [66,[2,12]],
		"/(dashboard)/settings/quickbooks": [67,[2]],
		"/(dashboard)/settings/security": [68,[2,13]],
		"/(dashboard)/settings/security/access": [69,[2,13]],
		"/(dashboard)/settings/security/activity": [70,[2,13]],
		"/(dashboard)/settings/security/approval": [71,[2,13]],
		"/(dashboard)/settings/security/devices": [72,[2,13]],
		"/(dashboard)/settings/transferrules": [73,[2]],
		"/(dashboard)/settings/users": [74,[2]],
		"/(dashboard)/transfer": [75,[2]],
		"/(dashboard)/uitest": [76,[2]],
		"/v0/[slug]": [85,[17]],
		"/(dashboard)/withdraw": [77,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';